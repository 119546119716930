export const utils = {
    convertToThaiDate(date: string, type: string) {

        if (!date) throw new Error("Date is not Undefined");

        const d = new Date(date);
        const month = [
            "มกราคม",
            "กุมภาพันธ์",
            "มีนาคม",
            "เมษายน",
            "พฤษภาคม",
            "มิถุนายน",
            "กรกฎาคม",
            "สิงหาคม",
            "กันยายน",
            "ตุลาคม",
            "พฤศจิกายน",
            "ธันวาคม"
        ];

        const daysOfWeekThai = [
            "วันอาทิตย์",
            "วันจันทร์",
            "วันอังคาร",
            "วันพุธ",
            "วันพฤหัสบดี",
            "วันศุกร์",
            "วันเสาร์"
        ];

        if (type === 'current')
            return daysOfWeekThai[d.getDay()] + ' ' + d.getDate() + ' ' + month[d.getMonth()] + ' ' + (d.getFullYear() + 543)

        return d.getDate() + ' ' + month[d.getMonth()] + ' ' + (d.getFullYear() + 543)
    }
}